export const DIALOG_TYPES = Object.freeze({
  DELETE: 'delete',
  EDIT: 'edit',
  ADD: 'add',
  NONE: null,
});

export const STATUS_OPTIONS = [
  { value: 'ACTIVE', translationKey: 'active' },
  { value: 'INACTIVE', translationKey: 'inactive' },
];

export const GLOBAL_ENTITY_REF = process.env.GLOBAL_ENTITY_REF ?? 'fe61ee41-ad04-419a-b94a-946132df5703';
