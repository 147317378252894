import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { GMButton } from '../../../cds-labs/GMButton';
import { Core, Modal } from 'connex-cds';
import { Divider } from 'antd';
import { useAddNewVehicleDevice, useVehicleWithoutDevices } from '../../../query-hooks/devices';

export const AddDevice = ({ entityRef }) => {
  const vehicles = useVehicleWithoutDevices(entityRef);
  const { closeModal } = Modal.useModalContext();
  const { mutate } = useAddNewVehicleDevice();
  const [sensorType, setSensorType] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const sensorTypes = ['AIR', 'DCM', 'PROBE'];

  const selectSensorType = event => {
    const sensorSelected = event.target.value;
    setSensorType(sensorSelected);
  };

  const selectVehicle = event => {
    setSelectedVehicle(event.target.value);
  };

  const saveDeviceVehicle = () => {
    setIsSaving(true);

    let dataToStore = {
      vehicleId: selectedVehicle.id,
      vehicleRef: selectedVehicle.crn,
      sensorType: sensorType,
      deviceConfigRefs: {},
    };

    mutate({
      entityRef: entityRef,
      data: { ...dataToStore },
    });

    closeModal();
  };

  return (
    <>
      {isSaving && (
        <Box>
          <Core.Spinner spin={isSaving}></Core.Spinner>
        </Box>
      )}
      {!isSaving && (
        <Box sx={{ width: '100%' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="vehicleId">
                  <FormattedMessage id={'vehicleId'} defaultMessage={'vehicleId'} />
                </InputLabel>
                {vehicles?.data && (
                  <Select
                    labelId="id"
                    id="id"
                    value={selectedVehicle}
                    label={<FormattedMessage id={'vehicleId'} defaultMessage={'vehicleId'} />}
                    onChange={selectVehicle}
                    style={{ width: '100%' }}
                  >
                    {vehicles?.data.map(vehicle => {
                      return <MenuItem value={vehicle}>{vehicle.id}</MenuItem>;
                    })}
                  </Select>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="sensorType">
                  <FormattedMessage id={'sensorType'} defaultMessage={'sensorType'} />
                </InputLabel>
                <Select
                  labelId="sensorType"
                  id="sensorType"
                  value={sensorType}
                  label={<FormattedMessage id={'sensorType'} defaultMessage={'sensorType'} />}
                  onChange={selectSensorType}
                  style={{ width: '100%' }}
                  disabled={selectedVehicle === ''}
                >
                  {sensorTypes.map(st => {
                    return <MenuItem value={st}>{st}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Box style={{ display: 'flex', justifyContent: 'end' }}>
            <GMButton id="cancelBtn" type="secondary" onClick={closeModal} />
            <GMButton
              id="save"
              type="primary"
              onClick={saveDeviceVehicle}
              disabled={sensorType === '' || selectedVehicle === ''}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
