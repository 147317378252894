import { Modal } from 'connex-cds';
import { useCallback, useEffect, useState } from 'react';
import DynamicComponentProvider from '../../devices/components/DynamicComponentProvider';
import { Button } from '@mui/material';
import { GLOBAL_ENTITY_REF } from '../../../constants';

export const CustomButton = ({ config }) => {
  const { openModal } = Modal.useModalContext();
  const defaultColorConfig = '#0288d1';
  const usrDefaultColorConfig = '#388e3c';
  const [defaultColor, setDefaultColor] = useState();
  const [deviceData, setDeviceData] = useState();

  useEffect(() => {
    if (config?.entityRef) {
      setDeviceData(config);
      if (config?.entityRef !== undefined || config?.entityRef !== GLOBAL_ENTITY_REF) {
        setDefaultColor(usrDefaultColorConfig);
      } else {
        setDefaultColor(defaultColorConfig);
      }
    }
  }, [config?.entityRef, config]);

  const openConfigModal = useCallback(
    data => {
      openModal({
        titleStringId: 'updateConfigModal',
        component: <DynamicComponentProvider config={data} deviceData={deviceData} mainComponent="config" />,
        width: '800px',
      });
    },
    [deviceData, openModal]
  );

  return (
    <Button
      id={`${config.id}`}
      className={`sensor-btn`}
      variant="contained"
      onClick={() => openConfigModal(config)}
      style={{ backgroundColor: `${defaultColor}` }}
      key={config?.crn}
    >
      {config.id}
    </Button>
  );
};
