import { Core, Form, Modal } from 'connex-cds';
import { camelCase, toUpper } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Box, FormControlLabel, FormGroup, Grid, Switch, TextField } from '@mui/material';
import { Divider } from 'antd';
import { GMButton } from '../../../cds-labs/GMButton';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { patchDeviceConfig, postNewDeviceConfig, updateDeviceConfigRefs } from '../utils/axiosRequests';
import { GMConfirmDialog } from '../../../cds-labs/GMConfirmDialog';
import { useQueryClient } from '@tanstack/react-query';
import { GLOBAL_ENTITY_REF } from '../../../constants';

const DynamicComponent = () => {
  const { entityRef } = useParams();
  const { closeModal } = Modal.useModalContext();
  const {
    Components,
    values,
    data,
    deviceData,
    deviceConfigData,
    closeDrawer,
    defaultConfig,
    newConfig,
    mainComponent,
    ...formContext
  } = Form.useFormContext();
  const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);
  const [configValues, setConfigValues] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [idConfig, setIdConfig] = useState();
  const [deviceType, setDeviceType] = useState();
  const [deviceCrn, setDeviceCrn] = useState();
  const [configCrn, setConfigCrn] = useState();
  const [isNewConfig, setIsNewConfig] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resetToDefaultBtn, setResetToDefaultBtn] = useState();
  const queryClient = useQueryClient();
  const [deviceEntityRef, setDeviceEntityRef] = useState();

  useEffect(() => {
    if (!deviceConfigData?.entityRef) {
      setIsNewConfig(true);
    }
  }, [deviceConfigData?.entityRef]);

  useEffect(() => {
    if (data) {
      setIdConfig(data?.id);
      setDeviceType(data?.deviceType);
      setDeviceCrn(deviceData?.crn ? deviceData?.crn : deviceConfigData?.crn);
      setConfigCrn(data?.crn);
      setConfigValues([...data?.configuration]);
      setDeviceEntityRef(deviceConfigData?.entityRef);
    }
  }, [data]);

  useEffect(() => {
    if (!defaultConfig && mainComponent === 'devices') {
      setResetToDefaultBtn(true);
    }

    if (defaultConfig && mainComponent === 'config' && deviceEntityRef && deviceEntityRef !== GLOBAL_ENTITY_REF) {
      setResetToDefaultBtn(true);
    }
  }, [defaultConfig, deviceEntityRef, mainComponent]);

  const handleChange = event => {
    let val = '';
    const itemChecked = event.target?.checked ? event.target.checked : '';
    const itemValue = event.target?.value ? event.target?.value : '';
    const itemName = event.target?.name ? event.target?.name : event.target?.id;

    if (itemChecked && itemChecked === true) {
      val = 'true';
    } else if (itemChecked && itemChecked === false) {
      val = 'false';
    } else if (itemValue === 'on') {
      val = 'false';
    } else {
      val = itemValue;
    }

    let newData = configValues.filter(el => el.key !== itemName);

    setConfigValues([...newData, { value: val, key: itemName }]);

    setHasChanged(true);
  };

  const saveConfig = useCallback(async () => {
    setIsSaving(true);
    const notNullData = [];
    let dataToStore = {};

    for (const item of configValues) {
      if (item.value) {
        notNullData.push(item);
      }
    }

    if (isNewConfig) {
      dataToStore = {
        id: idConfig,
        default: mainComponent === 'config' ? defaultConfig : false,
        deviceType: deviceType,
        configuration: notNullData,
      };

      await postNewDeviceConfig(entityRef, dataToStore, deviceData);
      queryClient.invalidateQueries({ queryKey: ['getDefaultConfig'] });
    } else {
      dataToStore = {
        id: idConfig,
        default: mainComponent === 'config' ? defaultConfig : false,
        configuration: notNullData,
      };

      await patchDeviceConfig(entityRef, dataToStore, configCrn);
      queryClient.invalidateQueries({ queryKey: ['getDefaultConfig'] });
    }

    closeModal();
  }, [configValues]);

  const onConfirm = async () => {
    setIsLoading(true);
    const currentDevicesConfig = deviceData?.deviceConfigRefs;
    const result = {};

    if (mainComponent === 'config') {
      await patchDeviceConfig(entityRef, { default: false }, deviceCrn);

      setOpen(false);
      closeModal();
      return;
    }

    for (let key in currentDevicesConfig) {
      if (currentDevicesConfig.hasOwnProperty(key) && key !== data?.deviceType) {
        result[key] = currentDevicesConfig[key];
      }
    }

    await updateDeviceConfigRefs(entityRef, deviceCrn, result, null);

    setOpen(false);
    closeModal();
    closeDrawer();
  };

  const openConfirmDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderComponent = item => {
    const componentName = pascalCase(item.path);
    const componentValue = configValues.find(({ key }) => key === item.path);

    if (item?.dataType === 'boolean') {
      return (
        <FormControlLabel
          label={<FormattedMessage id={componentName} defaultMessage={componentName} />}
          labelPlacement="top"
          style={{ width: '100%' }}
          key={componentName}
          control={
            <Switch
              size="small"
              name={item.path}
              checked={componentValue?.value === 'true' ? true : false}
              onChange={handleChange}
            />
          }
        />
      );
    }

    return (
      <TextField
        key={componentName}
        onChange={handleChange}
        label={<FormattedMessage id={componentName} defaultMessage={componentName} />}
        variant="outlined"
        value={componentValue?.value ? componentValue?.value : ''}
        style={{ margin: '10px auto', width: componentName === 'JsonParams' ? '95%' : '90%' }}
        id={item.path}
        multiline={componentName === 'JsonParams' ? true : false}
        rows={componentName === 'JsonParams' ? 10 : 1}
      />
    );
  };

  return (
    <>
      <Box sx={{ width: '100%', textAlign: 'center', maxHeight: '500px', overflowY: 'auto' }}>
        {isSaving && (
          <Box>
            <Core.Spinner spin={isSaving}></Core.Spinner>
          </Box>
        )}
        {!isSaving && (
          <FormGroup>
            {configValues && (
              <Grid container style={{ margin: '0px auto', width: '100%' }}>
                {formContext?.config?.fields?.map?.(field => {
                  const componentName = pascalCase(field.path);
                  if (componentName === 'JsonParams') {
                    return (
                      <Grid item xs={12} key={field?.path}>
                        {renderComponent(field)}
                      </Grid>
                    );
                  }
                  return (
                    <Grid item xs={6} key={field?.path}>
                      {renderComponent(field)}
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </FormGroup>
        )}
      </Box>
      <Divider></Divider>
      <Box style={{ display: 'flex' }}>
        {resetToDefaultBtn && (
          <Box style={{ display: 'flex', justifyContent: 'start', flex: 1 }}>
            <GMButton id="resetToDefault" type="error" variant="outlined" onClick={openConfirmDialog} />
          </Box>
        )}
        <Box style={{ display: 'flex', justifyContent: 'end', flex: 2 }}>
          <GMButton id="cancelBtn" type="secondary" onClick={closeModal} />
          <GMButton id="save" type="primary" onClick={saveConfig} disabled={!hasChanged} />
        </Box>
      </Box>

      <GMConfirmDialog
        id="ringtone-menu"
        keepMounted
        open={open}
        onClose={handleClose}
        title="deleteConfigConfirmTitle"
        content="deleteConfigConfirmText"
        okBtnText="confirmAction"
        onClick={onConfirm}
        loading={isLoading}
      />
    </>
  );
};

export default DynamicComponent;
